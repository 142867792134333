import axios from "axios";

class ShopifyService {

  constructor() {
    if (!process.env.REACT_APP_SHOPIFY_URL) {
      throw new Error("Missing env var SHOPIFY_URL");
    }
    
    if (!process.env.REACT_APP_SHOPIFY_VERSION) {
        throw new Error("Missing env var SHOPIFY_VERSION");
    }

    if (!process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN) {
        throw new Error("Missing env var SHOPIFY_STOREFRONT_TOKEN");
    }  

    this.baseUrl = `${process.env.REACT_APP_SHOPIFY_URL}/api/${process.env.REACT_APP_SHOPIFY_VERSION}/graphql.json`;
    this.headers = {
      Accept: "application/json",
      'Content-Type': 'application/json',
      'Accept-Language': 'fr',
      'X-Shopify-Storefront-Access-Token' : `${process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN}`
    };
  }

  getSearchQuery(sku) {
    return `{
        search(first: 25, query: "${sku}", types: PRODUCT) {
          totalCount
          edges {
            node {
              ... on Product {
                id
                title
                productType
                variants(first:100) {
                  edges {
                    node {
                      id
                      barcode
                      sku
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                metafields(
                  identifiers: [{namespace: "product", key: "name"}, {namespace: "product", key: "version"}]
                ) {
                  namespace
                  key
                  value
                  type
                }
              }
            }
          }
        }
      }`
  }

  cleanedId(id) {
    return id.split("/").at(-1)
  }

  async getProductDataFromSku(sku) {
    try {
      const response = await axios.post(`${this.baseUrl}`, JSON.stringify({ query: this.getSearchQuery(sku) }),
        { headers: { ...this.headers } })


      if (response.status === 200 && response.data && response.data?.data?.search?.edges && response.data.data.search.edges.length > 0) {

        var currentProduct = undefined
        var currentVariant = undefined

        for (const edge of response.data.data.search.edges) {

          let node = edge.node
          if (node && node.variants && node.variants.edges && node.variants.edges.length > 0) {
            const variant = node.variants.edges.map(v => v.node).find(v => v.sku === sku)
            if (variant) {
              currentProduct = node
              currentVariant = variant
              break
            }
          }
        }
        if (!currentProduct) {
          throw new Error(`getProductDataFromSku: no product found for sku ${sku}`)
        }

        let product = {
          productId: this.cleanedId(currentProduct.id),
          productTitle: currentProduct.title,
          productType: currentProduct.productType
        }

        if (currentVariant) {
          product.variantId = this.cleanedId(currentVariant.id)

          const size = currentVariant.selectedOptions.find(o => o.name === "Size")
          if (size) {
            product.size = size.value
          }

          const color = currentVariant.selectedOptions.find(o => o.name === "Color")
          if (color) {
            product.color = color.value
          }

          if (currentVariant.selectedOptions.length > 2) {
            const option3 = currentVariant.selectedOptions.find(o => o.name !== "Color" && o.name !== "Size")
            if (option3) {

              product.option3 = option3
            }
          }
        }

        if (currentProduct.metafields && currentProduct.metafields.length > 0) {
          const versionMetafield = currentProduct.metafields.find(m => m && m.namespace === "product" && m.key === "version")
          if (versionMetafield) {
            product.productVersion = versionMetafield.value
          }
        }

        return product
      }

      throw new Error(`getProductDataFromSku: failed with ${response.statusText}`)

    } catch (error) {
      console.error(`getProductDataFromSku: ${error.message}`)
      throw new Error(error)
    }
  }
}

const service = new ShopifyService();

export default service;

